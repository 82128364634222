@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {

display: flex;
flex-direction: row;
padding: 8px;
height: auto;
border-bottom-width: 2px;
/* border-width: 5px; */
/* border-color: black; */
/* justify-content: center; */
/* align-items: center; */
/* text-align: center; */

  
}

.headerLogo {
/* padding: 5px; */
/* border-bottom-width: 1px; */
/* border-width: 5px;
border-color: black; */
/* margin-bottom: 10px; */

}

.headerRight {
  margin-top: 12px;
  padding: 5px;
  position: absolute;
  right: 0;
  padding: 5px;
  display: flex;
flex-direction: row;
align-content: center;
/* justify-content: center; */
/* text-align: center; */


}

.boldHeaderText{
  font-size: medium;
  font-weight:600;
  margin-left: 24px;
  margin-right: 24px;
  font-family: Arial, Helvetica, sans-serif;
  align-content: center;
justify-content: center;
margin-top: 5px;
}

.solidButton {
  width: 200px;
  background-color: #01A2E8;
  color: white ;
  border-radius: 3px;
font-family: Arial, Helvetica, sans-serif;
font-weight: 600;
height: 32px;

}

.solidButtonHeader {
  width: 200px;
  background-color: #01A2E8;
  color: white ;
  border-radius: 3px;
font-family: Arial, Helvetica, sans-serif;
font-weight: 600;
height: 32px;
margin-right: 16px;
margin-left: 10px;
align-content: center;
justify-content: center;
}

.landingCard {
  width: 40%;
  /* align-content: center;
  justify-content: center; */
  background-color: white;
  border-width: 1px;
  border-color: red;
  height: 20%;
  position: relative;
  
  /* Peter Mortensen & Jeremiah https://stackoverflow.com/questions/15376634/how-can-i-center-a-div-within-another-div */
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}
.landingCard2 {
  /* width: 400px;
    height: 40%; */
    position: absolute;
    left: 50%;
    top: 50%;
    
    margin-top: 120px;
    margin-left: -160px;
    flex-direction: row;
    /* align-content: center;
    justify-content: center */
    ;
}
.landingInput {
  height: 48px;
  width: 400px;
  /* margin-left: auto;
  margin-right: auto; */
  margin-top: 126px;
  margin-left: -100px;
  padding:5px;
  border-radius: 3px;
  /* position:absolute; */
}
.searchButton{
  height: 48px;
  width: 180px;
  background-color: #01A2E8;
  margin-bottom: 60px;
  margin-left: 12px;
  border-radius: 2px;
  font-family: Arial, Helvetica, sans-serif;
  color: white;
  font-weight: 500;
  font-size: 18px;
}
.landingText{
  margin-left: 24px;
  font-size:80px;
  color: white;
  font-weight: 800;
  text-align: center;
  position: absolute;
  margin-top: 420px;
  margin-left: 620px;

}

.str-chat__channel-list-messenger-react__main {
  height: 50vh;
  max-height: 45%;
  width: 460px;
}



.controls {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
}

.controls-right {
  margin-left: auto;
}

.state {
  margin: 10px 0;
  font-family: monospace;
}

.state-title {
  color: #999;
  text-transform: uppercase;
}



@media print {


  body {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    margin-top: 8px;
    padding: 32px;
    height: auto;
    display: block;
    position: static; /* Ensures normal document flow */
    page-break-before: always;
    page-break-after: always;
    overflow: hidden;
  }
    @page {
    margin: 0; /* Removes automatic browser page margins */
  }
}